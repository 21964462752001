<template>
  <div class="container-fluid">
    <div class="form-head mb-4">
      <h2 class="text-black font-w600 mb-0">Monedero</h2>
    </div>
    <div class="row">
      <div class="col-xl-9 col-xxl-12">
        <div class="row">

          <div class="col-lg-8 mobile-only">
            <div class="card">
              <div class="card-header flex-wrap border-0 pb-0 pt-0 align-items-end">
                <ul class="navbar-nav header-right ul-options">
                  <NotificationMobile />
                  <li v-if="exchangeRate != null" class="nav-item">
                    <div class="d-none d-sm-flex weather-detail">
                      <span><i class="las la-money-bill-alt"></i>{{exchangeRate.currency_from.acronym}}</span>
                      = {{exchangeRate.value}} {{exchangeRate.currency_to.acronym}}
                    </div>
                    <div class="d-flex d-sm-none">
                      1 {{exchangeRate.currency_from.acronym}} = {{exchangeRate.value}} {{exchangeRate.currency_to.acronym}}
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div class="col-lg-8 pb-0">
            <div class="card">
              <div class="card-header flex-wrap border-0 pb-0 align-items-end">
                <div class="row d-flex align-items-center mb-3 mr-3">
                  <svg
                    class="mr-3"
                    width="68"
                    height="68"
                    viewBox="0 0 68 68"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M59.4999 31.688V19.8333C59.4999 19.0818 59.2014 18.3612 58.6701 17.8298C58.1387 17.2985 57.418 17 56.6666 17H11.3333C10.5818 17 9.86114 16.7014 9.32978 16.1701C8.79843 15.6387 8.49992 14.9181 8.49992 14.1666C8.49992 13.4152 8.79843 12.6945 9.32978 12.1632C9.86114 11.6318 10.5818 11.3333 11.3333 11.3333H56.6666C57.418 11.3333 58.1387 11.0348 58.6701 10.5034C59.2014 9.97208 59.4999 9.25141 59.4999 8.49996C59.4999 7.74851 59.2014 7.02784 58.6701 6.49649C58.1387 5.96514 57.418 5.66663 56.6666 5.66663H11.3333C9.07891 5.66663 6.9169 6.56216 5.32284 8.15622C3.72878 9.75028 2.83325 11.9123 2.83325 14.1666V53.8333C2.83325 56.0876 3.72878 58.2496 5.32284 59.8437C6.9169 61.4378 9.07891 62.3333 11.3333 62.3333H56.6666C57.418 62.3333 58.1387 62.0348 58.6701 61.5034C59.2014 60.9721 59.4999 60.2514 59.4999 59.5V47.6453C61.1561 47.0683 62.5917 45.9902 63.6076 44.5605C64.6235 43.1308 65.1693 41.4205 65.1693 39.6666C65.1693 37.9128 64.6235 36.2024 63.6076 34.7727C62.5917 33.3431 61.1561 32.265 59.4999 31.688ZM53.8333 56.6666H11.3333C10.5818 56.6666 9.86114 56.3681 9.32978 55.8368C8.79843 55.3054 8.49992 54.5847 8.49992 53.8333V22.1453C9.40731 22.4809 10.3658 22.6572 11.3333 22.6666H53.8333V31.1666H45.3333C43.0789 31.1666 40.9169 32.0622 39.3228 33.6562C37.7288 35.2503 36.8333 37.4123 36.8333 39.6666C36.8333 41.921 37.7288 44.083 39.3228 45.677C40.9169 47.2711 43.0789 48.1666 45.3333 48.1666H53.8333V56.6666ZM56.6666 42.5H45.3333C44.5818 42.5 43.8611 42.2015 43.3298 41.6701C42.7984 41.1387 42.4999 40.4181 42.4999 39.6666C42.4999 38.9152 42.7984 38.1945 43.3298 37.6632C43.8611 37.1318 44.5818 36.8333 45.3333 36.8333H56.6666C57.418 36.8333 58.1387 37.1318 58.6701 37.6632C59.2014 38.1945 59.4999 38.9152 59.4999 39.6666C59.4999 40.4181 59.2014 41.1387 58.6701 41.6701C58.1387 42.2015 57.418 42.5 56.6666 42.5Z"
                      fill="#1EAAE7"
                    />
                  </svg>
                  <div class="mr-auto">
                    <h5 class="fs-20 text-black font-w600">Saldo</h5>
                    <span v-if="walletBalance" class="text-num text-black font-w600">
                      {{ formatCurrency(walletBalance) }}</span
                    >
                    <span v-else class="text-num text-black font-w600">0.00</span>
                  </div>
                  <div class="pull-right">
                    <h5 class="fs-20 text-black font-w600">Pendiente</h5>
                    <span v-if="pendingBalance" class="text-num font-w600 amount-pending">{{
                      formatCurrency(pendingBalance)
                    }}</span>
                    <span v-else class="text-num font-w600 amount-pending">0.00</span>
                  </div>
                </div>
              </div>
              <div class="card-body">
                <div class="row align-items-center">
                  <div class="col-xl-12 col-xxl-4 col-sm-4 pr-1 pl-1">
                    <div class="card bgl-primary mb-0">
                      <div class="card-body p-2">
                        <div class="d-flex align-items-center">
                          <router-link class="d-flex align-items-center" to="/recarga-saldo/nueva">
                            <span class="bg-white rounded-circle p-3 mr-2">
                              <svg
                                width="28"
                                height="28"
                                viewBox="0 0 28 28"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g opacity="0.98" clip-path>
                                  <path
                                    d="M9.77812 2.0125C10.1062 2.69062 9.81641 3.51094 9.13828 3.83906C7.25156 4.74688 5.65469 6.15781 4.51719 7.92422C3.35234 9.73437 2.73438 11.8344 2.73438 14C2.73438 20.2125 7.7875 25.2656 14 25.2656C20.2125 25.2656 25.2656 20.2125 25.2656 14C25.2656 11.8344 24.6477 9.73437 23.4883 7.91875C22.3563 6.15234 20.7539 4.74141 18.8672 3.83359C18.1891 3.50547 17.8992 2.69063 18.2273 2.00703C18.5555 1.32891 19.3703 1.03906 20.0539 1.36719C22.4 2.49375 24.3852 4.24375 25.7906 6.44219C27.2344 8.69531 28 11.3094 28 14C28 17.7406 26.5453 21.257 23.8984 23.8984C21.257 26.5453 17.7406 28 14 28C10.2594 28 6.74297 26.5453 4.10156 23.8984C1.45469 21.2516 1.22342e-07 17.7406 1.66948e-07 14C1.99034e-07 11.3094 0.765625 8.69531 2.21484 6.44219C3.62578 4.24922 5.61094 2.49375 7.95156 1.36719C8.63516 1.04453 9.45 1.3289 9.77812 2.0125Z"
                                    fill="#1eaae7"
                                  />
                                  <path
                                    d="M8.67896 13.2726C8.41099 13.0047 8.27974 12.6547 8.27974 12.3047C8.27974 11.9547 8.41099 11.6047 8.67896 11.3367L12.1188 7.89685C12.6219 7.39373 13.2891 7.12029 13.9946 7.12029C14.7 7.12029 15.3727 7.3992 15.8704 7.89685L19.3102 11.3367C19.8461 11.8726 19.8461 12.7367 19.3102 13.2726C18.7743 13.8086 17.9102 13.8086 17.3743 13.2726L15.3563 11.2547L15.3563 19.0258C15.3563 19.7804 14.7438 20.3929 13.9891 20.3929C13.2344 20.3929 12.6219 19.7804 12.6219 19.0258L12.6219 11.2492L10.604 13.2672C10.079 13.8031 9.21489 13.8031 8.67896 13.2726Z"
                                    fill="#1eaae7"
                                  />
                                </g>
                                <defs>
                                  <clipPath id="clip11">
                                    <rect
                                      width="28"
                                      height="28"
                                      fill="1eaae7"
                                      transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 28 28)"
                                    />
                                  </clipPath>
                                </defs>
                              </svg>
                            </span>
                            <span class="fs-16">Recarga</span>
                          </router-link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-12 col-xxl-3 col-sm-3 pr-1 pl-1">
                    <div class="card bgl-success mb-0">
                      <div class="card-body p-2">
                        <div class="d-flex align-items-center">
                          <router-link
                            class="d-flex align-items-center"
                            style="color: #297f00"
                            to="/retiro-saldo/nuevo"
                          >
                            <span class="bg-white rounded-circle p-3 mr-2">
                              <svg
                                width="28"
                                height="28"
                                viewBox="0 0 16 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g clip-path="url(#clip3)">
                                  <path
                                    d="M10.4125 14.85C10.225 14.4625 10.3906 13.9937 10.7781 13.8062C11.8563 13.2875 12.7688 12.4812 13.4188 11.4719C14.0844 10.4375 14.4375 9.23749 14.4375 7.99999C14.4375 4.44999 11.55 1.56249 8 1.56249C4.45 1.56249 1.5625 4.44999 1.5625 7.99999C1.5625 9.23749 1.91562 10.4375 2.57812 11.475C3.225 12.4844 4.14062 13.2906 5.21875 13.8094C5.60625 13.9969 5.77187 14.4625 5.58437 14.8531C5.39687 15.2406 4.93125 15.4062 4.54062 15.2187C3.2 14.575 2.06562 13.575 1.2625 12.3187C0.4375 11.0312 -4.16897e-07 9.53749 -3.49691e-07 7.99999C-2.56258e-07 5.86249 0.83125 3.85312 2.34375 2.34374C3.85313 0.831242 5.8625 -7.37314e-06 8 -7.2797e-06C10.1375 -7.18627e-06 12.1469 0.831243 13.6563 2.34374C15.1688 3.85624 16 5.86249 16 7.99999C16 9.53749 15.5625 11.0312 14.7344 12.3187C13.9281 13.5719 12.7938 14.575 11.4563 15.2187C11.0656 15.4031 10.6 15.2406 10.4125 14.85Z"
                                    fill="#2bc155"
                                  />
                                  <path
                                    d="M11.0407 8.41563C11.1938 8.56876 11.2688 8.76876 11.2688 8.96876C11.2688 9.16876 11.1938 9.36876 11.0407 9.52188L9.07503 11.4875C8.78753 11.775 8.40628 11.9313 8.00315 11.9313C7.60003 11.9313 7.21565 11.7719 6.93127 11.4875L4.96565 9.52188C4.6594 9.21563 4.6594 8.72188 4.96565 8.41563C5.2719 8.10938 5.76565 8.10938 6.0719 8.41563L7.22502 9.56876L7.22502 5.12814C7.22502 4.69689 7.57503 4.34689 8.00628 4.34689C8.43753 4.34689 8.78753 4.69689 8.78753 5.12814L8.78753 9.57188L9.94065 8.41876C10.2407 8.11251 10.7344 8.11251 11.0407 8.41563Z"
                                    fill="#2bc155"
                                  />
                                </g>
                                <defs>
                                  <clipPath id="clip3">
                                    <rect
                                      width="16"
                                      height="16"
                                      fill="#2bc155"
                                      transform="matrix(-4.37114e-08 1 1 4.37114e-08 0 -7.62939e-06)"
                                    />
                                  </clipPath>
                                </defs>
                              </svg>
                            </span>
                            <span class="fs-16">Retiro</span>
                          </router-link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-12 col-xxl-5 col-sm-5 pr-1 pl-1">
                    <div class="card bgl-warning mb-0">
                      <div class="card-body p-2">
                        <div class="d-flex align-items-center">
                          <router-link
                            class="d-flex align-items-center"
                            style="color: #fe8024"
                            to="/transferencia/nueva"
                          >
                            <span class="bg-white rounded-circle p-3 mr-2">
                              <svg
                                width="28"
                                height="28"
                                viewBox="0 0 16 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                transform="rotate(270)"
                              >
                                <g clip-path="url(#clip3)">
                                  <path
                                    d="M10.4125 14.85C10.225 14.4625 10.3906 13.9937 10.7781 13.8062C11.8563 13.2875 12.7688 12.4812 13.4188 11.4719C14.0844 10.4375 14.4375 9.23749 14.4375 7.99999C14.4375 4.44999 11.55 1.56249 8 1.56249C4.45 1.56249 1.5625 4.44999 1.5625 7.99999C1.5625 9.23749 1.91562 10.4375 2.57812 11.475C3.225 12.4844 4.14062 13.2906 5.21875 13.8094C5.60625 13.9969 5.77187 14.4625 5.58437 14.8531C5.39687 15.2406 4.93125 15.4062 4.54062 15.2187C3.2 14.575 2.06562 13.575 1.2625 12.3187C0.4375 11.0312 -4.16897e-07 9.53749 -3.49691e-07 7.99999C-2.56258e-07 5.86249 0.83125 3.85312 2.34375 2.34374C3.85313 0.831242 5.8625 -7.37314e-06 8 -7.2797e-06C10.1375 -7.18627e-06 12.1469 0.831243 13.6563 2.34374C15.1688 3.85624 16 5.86249 16 7.99999C16 9.53749 15.5625 11.0312 14.7344 12.3187C13.9281 13.5719 12.7938 14.575 11.4563 15.2187C11.0656 15.4031 10.6 15.2406 10.4125 14.85Z"
                                    fill="#FE8024"
                                  />
                                  <path
                                    d="M11.0407 8.41563C11.1938 8.56876 11.2688 8.76876 11.2688 8.96876C11.2688 9.16876 11.1938 9.36876 11.0407 9.52188L9.07503 11.4875C8.78753 11.775 8.40628 11.9313 8.00315 11.9313C7.60003 11.9313 7.21565 11.7719 6.93127 11.4875L4.96565 9.52188C4.6594 9.21563 4.6594 8.72188 4.96565 8.41563C5.2719 8.10938 5.76565 8.10938 6.0719 8.41563L7.22502 9.56876L7.22502 5.12814C7.22502 4.69689 7.57503 4.34689 8.00628 4.34689C8.43753 4.34689 8.78753 4.69689 8.78753 5.12814L8.78753 9.57188L9.94065 8.41876C10.2407 8.11251 10.7344 8.11251 11.0407 8.41563Z"
                                    fill="#FE8024"
                                  />
                                </g>
                                <defs>
                                  <clipPath id="clip3">
                                    <rect
                                      width="16"
                                      height="16"
                                      fill="#FE8024"
                                      transform="matrix(-4.37114e-08 1 1 4.37114e-08 0 -7.62939e-06)"
                                    />
                                  </clipPath>
                                </defs>
                              </svg>
                            </span>
                            <span class="fs-16">Transferencia</span>
                          </router-link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4 pb-0">
            <div id="wallet-qr" class="card mb-0">
              <div class="card-header d-block border-0 pb-0">
                <div class="mb-sm-0 mb-3 mx-auto text-center">
                  <h4 class="fs-20 text-black mb-1 d-print-none">Mi QR</h4>
                  <span class="d-none d-print-block mb-3"
                    ><img src="@/assets/repay/images/aless-pay-logo.png" class="qr-logo img-fluid" alt="Logo"
                  /></span>
                </div>
              </div>
              <div class="card-body py-1 mb-5 mb-md-0">
                <div class="text-center">
                  <a class="mx-auto" @click="printQr" href="javascript:void(0)" title="Imprimir">
                    <qrcode-vue :value="walletUrl" size="160" level="L" renderAs="svg" />
                  </a>
                </div>
                <div class="d-flex justify-content-between flex-wrap align-items-center">
                  <div class="d-none d-print-block col-12 mx-auto pb-0 text-center">
                    <p class="fs-14 mb-1">
                      Usuario:
                      <span class="text-black font-w500">{{ curUser.username }}</span>
                    </p>
                    <p class="fs-14 mb-1">
                      Email:
                      <span class="text-black font-w500">{{ curUser.email }}</span>
                    </p>
                  </div>
                  <!--
                  <div class="col-12 mx-auto pt-0 text-center">
                    <p class="fs-14 mb-1">
                      Email:
                      <span class="text-black font-w500">{{
                        curUser.email
                      }}</span>
                    </p>
                  </div>
                  -->
                </div>
              </div>
            </div>
          </div>

          <div class="col-xl-12 col-lg-12 pb-0 pt-0">
            <latest-transfers></latest-transfers>
          </div>

          <div v-if="transactions.length > 0" class="col-xl-12 pt-lg-0">
            <div class="card">
              <div class="card-header d-block d-sm-flex border-0 pb-0">
                <div>
                  <h4 class="fs-20 text-black">Transacciones</h4>
                </div>
              </div>
              <div class="card-body payment-bx tab-content p-0">
                <div class="tab-pane active show fade" id="monthly" role="tabpanel">
                  <div id="accordion-one" class="accordion accordion-primary">
                    <div
                      v-for="(value, key) in transactions"
                      v-bind:key="key"
                      class="accordion__item border-bottom mb-0"
                    >
                      <div
                        class="d-flex flex-wrap align-items-center accordion__header collapsed rounded"
                        data-toggle="collapse"
                        :data-target="'#collapseTransaction' + key"
                      >
                        <div class="col-md-3 mb-lg-0 mb-3 d-flex align-items-center">
                          <div class="profile-image mx-4 mt-3 pt-4">
                            <span v-if="value.amount > 0" class="bg-success">
                              <svg
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g clip-path="url(#clip1)">
                                  <path
                                    d="M10.4125 1.15001C10.225 1.53751 10.3906 2.00627 10.7781 2.19377C11.8563 2.71252 12.7688 3.51876 13.4188 4.52814C14.0844 5.56251 14.4375 6.76251 14.4375 8.00001C14.4375 11.55 11.55 14.4375 8 14.4375C4.45 14.4375 1.5625 11.55 1.5625 8.00002C1.5625 6.76251 1.91562 5.56252 2.57812 4.52501C3.225 3.51564 4.14062 2.70939 5.21875 2.19064C5.60625 2.00314 5.77187 1.53752 5.58437 1.14689C5.39687 0.759391 4.93125 0.593765 4.54062 0.781265C3.2 1.42502 2.06562 2.42501 1.2625 3.68126C0.4375 4.96877 -4.16897e-07 6.46251 -3.49691e-07 8.00002C-2.56258e-07 10.1375 0.83125 12.1469 2.34375 13.6563C3.85313 15.1688 5.8625 16 8 16C10.1375 16 12.1469 15.1688 13.6563 13.6563C15.1688 12.1438 16 10.1375 16 8.00001C16 6.46251 15.5625 4.96876 14.7344 3.68126C13.9281 2.42814 12.7938 1.42501 11.4563 0.781265C11.0656 0.59689 10.6 0.759389 10.4125 1.15001Z"
                                    fill="white"
                                  />
                                  <path
                                    d="M11.0407 7.58437C11.1938 7.43125 11.2688 7.23125 11.2688 7.03125C11.2688 6.83125 11.1938 6.63125 11.0407 6.47812L9.07503 4.5125C8.78753 4.225 8.40628 4.06875 8.00315 4.06875C7.60003 4.06875 7.21565 4.22813 6.93127 4.5125L4.96565 6.47812C4.6594 6.78437 4.6594 7.27812 4.96565 7.58437C5.2719 7.89062 5.76565 7.89062 6.0719 7.58437L7.22502 6.43125L7.22502 10.8719C7.22502 11.3031 7.57503 11.6531 8.00628 11.6531C8.43753 11.6531 8.78753 11.3031 8.78753 10.8719L8.78753 6.42812L9.94065 7.58125C10.2407 7.8875 10.7344 7.8875 11.0407 7.58437Z"
                                    fill="white"
                                  />
                                </g>
                                <defs>
                                  <clipPath id="clip1">
                                    <rect width="16" height="16" fill="white" transform="translate(0 16) rotate(-90)" />
                                  </clipPath>
                                </defs>
                              </svg>
                            </span>
                            <span v-else class="bg-danger">
                              <svg
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g clip-path="url(#clip3)">
                                  <path
                                    d="M10.4125 14.85C10.225 14.4625 10.3906 13.9937 10.7781 13.8062C11.8563 13.2875 12.7688 12.4812 13.4188 11.4719C14.0844 10.4375 14.4375 9.23749 14.4375 7.99999C14.4375 4.44999 11.55 1.56249 8 1.56249C4.45 1.56249 1.5625 4.44999 1.5625 7.99999C1.5625 9.23749 1.91562 10.4375 2.57812 11.475C3.225 12.4844 4.14062 13.2906 5.21875 13.8094C5.60625 13.9969 5.77187 14.4625 5.58437 14.8531C5.39687 15.2406 4.93125 15.4062 4.54062 15.2187C3.2 14.575 2.06562 13.575 1.2625 12.3187C0.4375 11.0312 -4.16897e-07 9.53749 -3.49691e-07 7.99999C-2.56258e-07 5.86249 0.83125 3.85312 2.34375 2.34374C3.85313 0.831242 5.8625 -7.37314e-06 8 -7.2797e-06C10.1375 -7.18627e-06 12.1469 0.831243 13.6563 2.34374C15.1688 3.85624 16 5.86249 16 7.99999C16 9.53749 15.5625 11.0312 14.7344 12.3187C13.9281 13.5719 12.7938 14.575 11.4563 15.2187C11.0656 15.4031 10.6 15.2406 10.4125 14.85Z"
                                    fill="white"
                                  />
                                  <path
                                    d="M11.0407 8.41563C11.1938 8.56876 11.2688 8.76876 11.2688 8.96876C11.2688 9.16876 11.1938 9.36876 11.0407 9.52188L9.07503 11.4875C8.78753 11.775 8.40628 11.9313 8.00315 11.9313C7.60003 11.9313 7.21565 11.7719 6.93127 11.4875L4.96565 9.52188C4.6594 9.21563 4.6594 8.72188 4.96565 8.41563C5.2719 8.10938 5.76565 8.10938 6.0719 8.41563L7.22502 9.56876L7.22502 5.12814C7.22502 4.69689 7.57503 4.34689 8.00628 4.34689C8.43753 4.34689 8.78753 4.69689 8.78753 5.12814L8.78753 9.57188L9.94065 8.41876C10.2407 8.11251 10.7344 8.11251 11.0407 8.41563Z"
                                    fill="white"
                                  />
                                </g>
                                <defs>
                                  <clipPath id="clip3">
                                    <rect
                                      width="16"
                                      height="16"
                                      fill="white"
                                      transform="matrix(-4.37114e-08 1 1 4.37114e-08 0 -7.62939e-06)"
                                    />
                                  </clipPath>
                                </defs>
                              </svg>
                            </span>
                          </div>
                          <div>
                            <h6 class="fs-16 font-w700 mb-0">
                              <a v-if="value.destiny" class="text-black" href="javascript:void(0)">{{
                                value.destiny.full_name != " " ? value.destiny.full_name : value.destiny.email
                              }}</a>
                              <a v-else class="text-black" href="javascript:void(0)">{{
                                value.transaction_type.display_name
                              }}</a>
                            </h6>
                            <span class="fs-14">{{ value.transaction_type.display_name }}</span>
                          </div>
                        </div>
                        <span class="mb-lg-0 mb-3 text-black px-2">
                          {{ getDate(value.date) }}
                          <br />
                          {{ getTime(value.date) }}
                        </span>
                        <span class="mb-lg-0 mb-3 text-black font-w600 px-2">{{ value.amount_display }}</span>
                        <span class="col-md-3 mb-lg-0 mb-3 text-black font-w600 px-2 text-center d-none d-md-block">{{
                          value.payment_type
                        }}</span>
                        <a
                          v-if="value.status"
                          :class="[
                            'mb-lg-0',
                            'mb-3',
                            'btn',
                            'btn-outline-' + value.status.name,
                            'btn-md',
                            'btn-rounded',
                            'mx-2',
                          ]"
                          href="javascript:void(0)"
                          >{{ value.status.display_name }}</a
                        >
                        <span class="accordion__header--indicator"></span>
                      </div>
                      <div
                        :id="'collapseTransaction' + key"
                        class="collapse accordion__body"
                        data-parent="#accordion-one"
                      >
                        <div class="d-flex flex-wrap align-items-center accordion__body--text p-0">
                          <div class="mr-3 mb-3">
                            <p class="fs-12 mb-2">ID</p>
                            <span class="text-black font-w500">#{{ value.id }}</span>
                          </div>
                          <div class="mr-3 mb-3">
                            <p class="fs-12 mb-2">Método de Pago</p>
                            <span class="text-black font-w500">{{ value.payment_type }}</span>
                          </div>
                          <div class="mr-3 mb-3">
                            <p class="fs-12 mb-2">Fecha</p>
                            <span class="text-black font-w500">{{ getDate(value.date) }}</span>
                          </div>
                          <div class="d-flex col-md-4 p-3 rounded bgl-dark align-items-center mb-3">
                            <svg
                              class="mr-3"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M12 1C9.82441 1 7.69767 1.64514 5.88873 2.85384C4.07979 4.06253 2.66989 5.7805 1.83733 7.79049C1.00477 9.80047 0.786929 12.0122 1.21137 14.146C1.6358 16.2798 2.68345 18.2398 4.22183 19.7782C5.76021 21.3166 7.72022 22.3642 9.85401 22.7887C11.9878 23.2131 14.1995 22.9953 16.2095 22.1627C18.2195 21.3301 19.9375 19.9202 21.1462 18.1113C22.3549 16.3023 23 14.1756 23 12C22.9966 9.08368 21.8365 6.28778 19.7744 4.22563C17.7122 2.16347 14.9163 1.00344 12 1ZM12 21C10.22 21 8.47992 20.4722 6.99987 19.4832C5.51983 18.4943 4.36628 17.0887 3.68509 15.4442C3.0039 13.7996 2.82567 11.99 3.17294 10.2442C3.5202 8.49836 4.37737 6.89471 5.63604 5.63604C6.89472 4.37737 8.49836 3.5202 10.2442 3.17293C11.99 2.82567 13.7996 3.0039 15.4442 3.68509C17.0887 4.36627 18.4943 5.51983 19.4832 6.99987C20.4722 8.47991 21 10.22 21 12C20.9971 14.3861 20.0479 16.6736 18.3608 18.3608C16.6736 20.048 14.3861 20.9971 12 21Z"
                                fill="#A4A4A4"
                              />
                              <path
                                d="M12 9C11.7348 9 11.4804 9.10536 11.2929 9.29289C11.1054 9.48043 11 9.73478 11 10V17C11 17.2652 11.1054 17.5196 11.2929 17.7071C11.4804 17.8946 11.7348 18 12 18C12.2652 18 12.5196 17.8946 12.7071 17.7071C12.8947 17.5196 13 17.2652 13 17V10C13 9.73478 12.8947 9.48043 12.7071 9.29289C12.5196 9.10536 12.2652 9 12 9Z"
                                fill="#A4A4A4"
                              />
                              <path
                                d="M12 8C12.5523 8 13 7.55228 13 7C13 6.44771 12.5523 6 12 6C11.4477 6 11 6.44771 11 7C11 7.55228 11.4477 8 12 8Z"
                                fill="#A4A4A4"
                              />
                            </svg>
                            <p class="mb-0 fs-14">
                              {{ value.transaction_type.description }}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style src="@/assets/scss/content.scss" lang="scss" scoped></style>

<script>
//import '@/assets/scss/global-main.scss'
import { EventBus } from "@/main";
import global from "@/helpers/global";
import table from "@/helpers/table";
import Printd from "printd";
import QrcodeVue from "qrcode.vue";
import LatestTransfers from "./LatestTransfers";
import { handleMenuClick } from "@/assets/js/wallet/wallet.js";
import NotificationMobile from "@/components/Notification/NotificationMobile.vue";

export default {
  data() {
    return {
      walletUrl: "",
      walletBalance: "",
      pendingBalance: "",
      transactions: [],
      exchangeRate: null,
    };
  },
  mixins: [global, table],
  components: {
    LatestTransfers,
    QrcodeVue,
    NotificationMobile,
  },
  beforeMount() {
    this.walletUrl = window.location.origin + process.env.BASE_URL + "monedero/" + this.curUser.username;

    if (this.curUser.person != undefined) {
      axios
        .get(process.env.VUE_APP_API_URL + "/api/v1/profiles/person/get/" + this.curUser.person.id)
        .then((response) => {
          //console.log(response.data.person);
          this.walletBalance = response.data.person.wallet.amount;
          this.pendingBalance = response.data.person.wallet.recharge_amount_pending;
          //console.log(response.data.message);
        })
        .catch((error) => {
          this.errors = [];

          this.walletBalance = "";

          if (error.response != undefined) {
            this.errors = error.response.data.errors ?? [];

            console.log(error.response.data.error);
          }
        });
    } else if (this.curUser.company != undefined) {
      axios
        .get(process.env.VUE_APP_API_URL + "/api/v1/profiles/company/get/" + this.curUser.company.id)
        .then((response) => {
          //console.log(response.data.company);
          this.walletBalance = response.data.company.wallet.amount;
          //console.log(response.data.message);
        })
        .catch((error) => {
          this.errors = [];

          this.walletBalance = "";

          if (error.response != undefined) {
            this.errors = error.response.data.errors ?? [];

            console.log(error.response.data.error);
          }
        });
    }

    axios
      .get(process.env.VUE_APP_API_URL + "/api/v1/transactions/last/10")
      .then((response) => {
        this.transactions = response.data.transactions;
        //console.log(response.data.transactions);
      })
      .catch((error) => {
        this.errors = [];

        if (error.response != undefined) {
          this.errors = error.response.data.errors ?? [];

          EventBus.$emit("showErrors", {
            msg: error.response.data.error,
          });

          console.log(error.response.data.error);
        }
      })
      .finally(() => {
        this.fillCustomFields();
      });

      let exchange_rate = {
      date: this.formatDate(new Date().toISOString().slice(0, 10)),
      currency_from_acronym: "USD",
      currency_to_acronym: "VES",
    };

    axios
      .post(
        process.env.VUE_APP_API_URL +
          "/api/v1/exchange-rate/search-by-currencies",
        exchange_rate
      )
      .then((response) => {
        this.exchangeRate = response.data.exchangeRate;
        //console.log(response.data.exchangeRate);
      })
      .catch((error) => {
        this.errors = [];

        this.exchangeRate = null;

        if (error.response != undefined) {
          this.errors = error.response.data.errors ?? [];

          console.log(error.response.data.error);
        }
      });
  },
  mounted() {
    handleMenuClick();
  },

  methods: {
    fillCustomFields() {
      var tc = this._clone(this.transactions);
      tc.forEach(async (t, key) => {
        switch (t.transaction_type.name) {
          case "recharge":
            this.transactions = [];
            tc[key].payment_type = await this.getRechargePayment(t.recharge_request.recharge_way_id);
            tc[key].recipient.full_name = "Aless Pay";
            this.transactions = tc;
            tc = this._clone(this.transactions);
            break;
          case "withdrawal":
            this.transactions = [];
            tc[key].payment_type = await this.getWithdrawalPayment(t.withdrawal_request.withdrawal_way_id);
            tc[key].recipient.full_name = "Aless Pay";
            this.transactions = tc;
            tc = this._clone(this.transactions);
            break;
          default:
            this.transactions = [];
            tc[key].recipient.full_name = await this.getUserFromTransfer(t.recipient);
            tc[key].payment_type = "-";
            this.transactions = tc;
            tc = this._clone(this.transactions);
        }
      });
    },
    async getRechargePayment(id) {
      let recharge_payment = "";
      await axios
        .get(process.env.VUE_APP_API_URL + "/api/v1/recharge-way/get/" + id)
        .then((response) => {
          recharge_payment = response.data.recharge_way.name;
        })
        .catch((error) => {
          this.errors = [];

          if (error.response != undefined) {
            this.errors = error.response.data.errors ?? [];
            //console.log(error.response.data.error);
          }

          recharge_payment = "";
        });

      return recharge_payment;
    },
    async getWithdrawalPayment(id) {
      let withdrawal_payment = "";
      await axios
        .get(process.env.VUE_APP_API_URL + "/api/v1/withdrawal-way/get/" + id)
        .then((response) => {
          withdrawal_payment = response.data.withdrawal_way.name;
        })
        .catch((error) => {
          this.errors = [];

          if (error.response != undefined) {
            this.errors = error.response.data.errors ?? [];
            console.log(error.response.data.error);
          }

          withdrawal_payment = "";
        });

      return withdrawal_payment;
    },
    getDate: function (dateUnformatted) {
      if (dateUnformatted == "" || dateUnformatted == null) {
        return "";
      }

      const day = dateUnformatted.substr(0, 2);
      const month = dateUnformatted.substr(3, 2);
      const year = dateUnformatted.substr(6, 4);

      const date = new Date(month + "/" + day + "/" + year);

      const dateTimeFormat = new Intl.DateTimeFormat("es", {
        year: "numeric",
        month: "short",
        day: "numeric",
      }).format(date);

      return dateTimeFormat;
    },
    getTime: function (dateUnformatted) {
      if (dateUnformatted == "" || dateUnformatted == null) {
        return "";
      }

      const hour = dateUnformatted.substr(11, 2);
      const minutes = dateUnformatted.substr(14, 2);

      const period = hour >= 12 ? "PM" : "AM";

      return `${hour}:${minutes} ${period}`;
    },
    async getUserFromTransfer(recipient) {
      let full_name = "";
      if (recipient.person_id) {
        await axios
          .get(process.env.VUE_APP_API_URL + "/api/v1/profiles/person/get/" + recipient.person_id)
          .then((response) => {
            full_name = response.data.person.user.email;
          })
          .catch((error) => {
            this.errors = [];

            if (error.response != undefined) {
              this.errors = error.response.data.errors ?? [];
              console.log(error.response.data.error);
            }

            full_name = "";
          });
      } else if (recipient.company_id) {
        await axios
          .get(process.env.VUE_APP_API_URL + "/api/v1/profiles/company/get/" + recipient.company_id)
          .then((response) => {
            full_name = response.data.company.user.email;
          })
          .catch((error) => {
            this.errors = [];

            if (error.response != undefined) {
              this.errors = error.response.data.errors ?? [];
              console.log(error.response.data.error);
            }

            full_name = "";
          });
      } else {
        full_name = "";
      }

      return full_name;
    },
    printQr: function () {
      const cssText = `
            #wallet-qr .card-header, .mx-auto {
              display: inline-block;
              font-family: "poppins", sans-serif;
              text-align: center;
              width: 100%;
            }
            #wallet-qr .card-body {
              display: inline-block;
              font-family: "poppins", sans-serif;
              width: 100%;
            }
            #wallet-qr .card-body svg {
              width: 240px !important;
              height: 240px !important;
              max-height: 100% !important;
            }
            #wallet-qr .qr-logo {
              width: 200px !important;
            }
            #wallet-qr .col-12 {
              margin-top: 10px !important;
            }
            #wallet-qr .mb-3 {
              margin-bottom: 30px !important;
            }
            #wallet-qr .col-12 p {
              margin: 4px 0 !important;
            }
            #wallet-qr .d-print-none {
              display: none !important;
            }
          `;

      new Printd().print(document.getElementById("wallet-qr"), [cssText]);
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${month}/${day}/${year}`;
    },
  },
};
</script>

<style lang="css" scoped>
.amount-pending {
  color: #ff8f16;
}

/* Oculta el <ul> por defecto */
.mobile-only {
  display: none;
}

/* Muestra el <ul> solo en pantallas de celular */
@media (max-width: 768px) {
  .mobile-only {
    display: block;
  }

  .ul-options {
    display: flex; /* Hace que los <li> se alineen en una fila */
    flex-direction: row;
    align-items: center; /* Alinea verticalmente los elementos dentro del contenedor */
    list-style: none; /* Elimina los puntos de la lista */
    padding: 0;
    margin: 0;
  }

  .ul-options li {
    margin: 0 10px; /* Espaciado horizontal entre los <li> */
  }

  .ul-options .weather-detail span {
    height: 39px;
    background: #1EAAE7;
    line-height: 40px;
    font-size: 16px;
    padding: 0px 20px;
    margin-right: 10px;
    border-radius: 4rem;
    color: #fff;
  }
}
</style>
